<template>
    <main>
        <slot name="hero" />

        <services :limit="3" :toOverview="true" />

        <section v-if="latestNews" class="container news">
            <div v-if="latestNews.variables.hasOwnProperty('image')" class="image">
                <figure :style="getImage(latestNews)" />
            </div>

            <div class="preview">
                <h2>
                    <strong data-text="Nieuws" />

                    <span>
                        {{ latestNews.title }}
                    </span>
                </h2>

                <div v-html="latestNews.variables.previewText" />

                <p>
                    <router-link :to="to(latestNews)" class="button primary stylized" data-text="Lees meer" />

                    <router-link :to="to(newsPage)" class="button ghost">
                        <i class="fas fa-angle-right"></i>
                        Naar het nieuws overzicht
                    </router-link>
                </p>
            </div>
        </section>
        
        <team-members />
    </main>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
// ScrollTrigger.normalizeScroll(true);
gsap.registerPlugin(ScrollTrigger);

import { mapGetters } from 'vuex';

import Services from '@/components/ServicesSection.vue';
import TeamMembers from '@/components/TeamMembers.vue';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        TeamMembers,
        Services,
    },


    computed: {
        ...mapGetters([
            'newsPage',
        ]),


        latestNews()
        {
            return vm.newsPage && vm.newsPage.sections.length === 0 ? null : vm.newsPage.sections[vm.newsPage.sections.length - 1];
        },
    },


	setup()
	{
		const { getImage, to } = useFunctions();


		return {
            getImage,
            to,
		};
	},


    created()
    {
        vm = this;
    },


    mounted() {
        let imgAnim = {
                opacity: 0,
                duration: 1.3,
                ease: "expo",
                scrollTrigger: {
                    start: 'middle bottom',
                }
            },
            txtAnim = JSON.parse(JSON.stringify(imgAnim)),
            img = document.querySelector('.news .image'),
            txt = document.querySelector('.news .preview'),
            x = '5vw';

        imgAnim.x = `-${x}`;
        imgAnim.scrollTrigger.trigger = img;
        
        txtAnim.x = x;
        txtAnim.scrollTrigger.trigger = txt;

        gsap.from(img, imgAnim)
        gsap.from(txt, txtAnim)
    },
}
</script>