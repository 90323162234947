<template>
    <section v-if="hasHero" class="container hero" :style="styles">
        <div class="hero-content">
            <div v-if="page.template != 6" class="content" v-html="page.variables.ctaText" />
            <div v-else class="content">
                <h4>
                    Nieuws & updates
                </h4>
    
                <h1>
                    {{ page.title }}
                </h1>
            </div>
    
            <router-link v-if="page.hasOwnProperty('variables') && page.variables.hasOwnProperty('ctaButtonUrl')" :to="to(page.variables.ctaButtonUrl)" class="button stylized" :data-text="page.variables.ctaButtonText" />
        </div>
    </section>

    <div class="overflow-wrapper slogan" v-if="[1, 3].indexOf(page.template) >= 0 && page.content.length > 0" tabindex="-1">
        <section class="skewed">
            <div class="unskewed" v-html="page.content" />
        </section>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        hasHero()
        {
            if (vm.page.template == 6) return true;
            if (
                Object.prototype.hasOwnProperty.call(vm.page, 'variables') &&
                (
                    Object.prototype.hasOwnProperty.call(vm.page.variables, 'ctaText') ||
                    Object.prototype.hasOwnProperty.call(vm.page.variables, 'ctaButtonText')
                )
            ) return true;

            return false;
        },


        styles()
        {
            return Object.prototype.hasOwnProperty.call(vm.page, 'variables') && Object.prototype.hasOwnProperty.call(vm.page.variables, 'image') ? `background-image: url('${vm.base}${vm.page.variables.image}')` : '';
        },
    },
    
    
    setup()
    {
        const { to } = useFunctions();
        
        
        return {
            to,
        };
    },
    
    
    created()
    {
        vm = this;
    }
}
</script>