<template>
    <section class="container row">
        <h2>
            Het team
        </h2>

        <div class="team-member" v-for="(tm, id) in team" :key="id">
            <a href="#" @click.prevent="setActive(id)">
                <figure class="square" :style="getImage(tm)" />
    
                <div class="hover">
                    <i class="far fa-search-plus fa-lg"></i>
    
                    <span>
                        {{ tm.pagetitle }}
                    </span>
                </div>
            </a>
        </div>


        <transition name="fade" mode="out-in">
            <div v-if="teamMember" class="modal" @click.self="setActive(-1)" role="dialog">
                <div class="modal-body">
                    <a href="#" @click.prevent="setActive(-1)" class="toggle">
                        <i class="fas fa-times"></i>
                    </a>
                    
                    <div class="modal-content team-member-info">
                        <div class="image">
                            <figure :style="getImage(teamMember)" />
    
                            <a :href="`mailto:${teamMember.variables.email}`" class="button">
                                <i class="far fa-envelope"></i>
    
                                E-mail
                                {{ teamMember.variables.name }}
                            </a>
                        </div>
    
                        <div class="info">
                            <h3 v-html="teamMember.pagetitle" />
    
                            <div v-html="teamMember.content" />
                        </div>
                    </div>
                </div>
            </div>
        </transition>

    </section>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { mapGetters } from 'vuex';

var vm;

export default {
    data()
    {
        return {
            active: -1,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'pages',
            'screenWidth',
        ]),


        team()
        {
            let team = [];

            vm.pages.forEach(p => {
                if (p.template == 8) team.push(p)
            })

            return team;
        },


        teamMember()
        {
            return vm.active >= 0 && vm.active < vm.team.length ? vm.team[vm.active] : false;
        },
    },


    methods: {
        getImage(obj)
        {
            return `background-image: url('${vm.base}${obj.variables.image}')`
        },


        setActive(id)
        {
            vm.active = id;
        },
    },


    created()
    {
        vm = this;
    },


    mounted() {
        let perRow = 1;

        if (vm.screenWidth >= 1920) perRow = 4;
        else if (vm.screenWidth >= 768) perRow = 2;

        document.querySelectorAll('.team-member').forEach((el, id) => {
            let rowId = Math.floor(id / perRow),
                onRowId = ((id / perRow) - rowId) * perRow;

            gsap.from(el, {
                scale: 0.39,
                opacity: 0,
                duration: 1,
                delay: perRow === 1 ? 0 : (onRowId + rowId) * 0.13,
                ease: "expo",
                scrollTrigger: {
                    trigger: el,
                    start: 'middle bottom',
                }
            })
        });
    },


    watch: {
        active(to, from)
        {
            vm.$nextTick(() => {
                const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
                const modal = document.querySelector('.modal');
    
                const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
                const focusableContent = modal.querySelectorAll(focusableElements);
                const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    
                if (to >= 0)
                {
                    // NOTE: the event listener is removed when the modal is removed
                    modal.addEventListener('keydown', (e) => {
                        let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
    
                        if (!isTabPressed) return;
    
                        if (e.shiftKey)
                        {
                            if (document.activeElement === firstFocusableElement)
                            {
                                lastFocusableElement.focus();
                                e.preventDefault();
                            }
                        }
                        else
                        {
                            if (document.activeElement === lastFocusableElement)
                            {
                                firstFocusableElement.focus();
                                e.preventDefault();
                            }
                        }
                    });
    
                    firstFocusableElement.focus();
                }
                else
                {
                    // When closing a modal, focus on the element that opened it to begin with
                    document.querySelector(`.team-member:nth-of-type(${from + 1}) a`).focus();
                }
            })
        },
    }
}
</script>