import { computed } from 'vue';
import { useStore } from 'vuex';

export default function useFunctions() {
	const store = useStore();

	const base = computed(() => {
		return store.state.base;
	});

    const pages = computed(() => {
		return store.state.pages;
	});


    function getImage(obj)
    {
        return `background-image: url('${base.value}${obj.variables.image}')`
    }


	function to(to)
	{
        let pPath = null;

        if (typeof(to) == 'object')
        {
            pPath = to.path.length > 1 ? '/' + to.path.slice(0, -1) : '/';
        }
        else
        {
            pages.value.forEach(p => {
                if (p.id == to)
                {
                    pPath = p.path.length > 1 ? '/' + p.path.slice(0, -1) : '/';
                }
            })
        }

		return {
            path: pPath,
        };
	}


	return {
        getImage,
		to,
	};
}