<template>
	<transition-group name="load" mode="out-in">
		<article v-if="loaded" key="path" @click="onClick">
			<teleport to="head">
				<title>
					{{ meta.title }}
				</title>
				
				<meta name="description" :content="meta.description">
				<meta name="keywords" :content="meta.keywords">
				
				<meta property="og:description" :content="meta.description">
				<meta property="og:title" :content="meta.title" />
				<meta property="og:image" :content="meta.image" />
			</teleport>
			

			<app-header />
			

			<router-view v-if="page !== {}" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component
						:is="Component"
						:key="routePath"
						:page="page"
					>
                        <template v-slot:hero>
                            <HeroSection :page="page" />
                        </template>
                    </component>
				</transition>
			</router-view>
			

			<app-footer />
			
		</article>


		<loader-element v-else key="loader" />

	</transition-group>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

import usePage from '@/hooks/page.js';

import AppFooter from '@/components/AppFooter.vue';
import AppHeader from '@/components/AppHeader.vue';
import HeroSection from '@/components/HeroSection.vue';
import LoaderElement from '@/components/LoaderElement.vue';

var vm;

export default {
	components: {
		AppFooter,
		AppHeader,
        HeroSection,
		LoaderElement,
	},


	data()
	{
		return {
			randomSponsors: [],
		};
	},


	computed: {
		...mapGetters([
			'duration',
			'pages',
			'siteName',
			'structure',
		]),


		loaded()
		{
			let s = Object.keys(vm.structure),
				p = Object.keys(vm.pages);
			
			return s.length > 0 && p.length > 0;
		},


		meta()
		{
			// If no page is loaded yet, return false
			if (Object.keys(vm.page).length === 0 || Object.prototype.hasOwnProperty.call(vm.page, 'temp')) return false;

			// let view = vm.photo !== null ? vm.photo : vm.page;
			let view = vm.page;

			let title = view.title + ' | ' + vm.siteName;

			let img = view.hasImage ? view.variables.image : 'img/icons/android-chrome-512x512.png';
			
			let meta = {
				description: view.description,
				keywords: view.keywords,
				title: title,
				image: img,
			};
			

			return meta;
		},


		routePath()
		{
			let p = vm.$route.fullPath;

			return p;
		},
	},


	methods: {
		...mapActions([
			'_initialize',
            'updateScreenWidth',
		]),


        resizeListener()
        {
            let sw = window.innerWidth;
            vm.updateScreenWidth(sw);

            vm.$nextTick(() => {
                if (navigator.userAgent.match(/iPhone|iPad|iPod/i))
                {
                    document.documentElement.classList.add('ios');
                }
                else
                {
                    document.documentElement.classList.remove('ios');
                }
            })
        },
	},


	setup()
	{
		const { page } = usePage();


		return {
			page,
		};
	},


	created()
	{
		vm = this;

        vm._initialize();

		window.addEventListener('resize', vm.resizeListener);
        vm.resizeListener();
	},
    

	unmounted()
	{
		window.removeEventListener('resize', vm.resizeListener);
	},


    // watch: {
    //     $route ()
    //     {
    //         vm.randomizeSponsors();
    //     },
    // }
};
</script>


<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/scss/app.scss';
</style>
