<template>
    <form class="contact-form">

        <transition name="fade" mode="out-in">

            <loader-element v-if="processing" />

        </transition>

        <div v-if="success === false" class="feedback warning">
            Helaas konden we je bericht niet verzenden. Controleer het formulier op volledigheid en correctheid. Als het
            probleem zich blijft voordoen, mail dan direct met
            <a :href="'mailto:' + page.variables.email">
                {{ page.variables.email }}
            </a>
        </div>

        <div v-if="success">
            We hebben je bericht ontvangen. Deze zullen we z.s.m. proberen te behandelen.
        </div>

        <div v-else>
            <ul>
                <li :class="contact.name.length > 0 ? 'filled' : ''">
                    <input type="text" placeholder="Uw naam... *" v-model="contact.name" />
                </li>

                <li :class="validEmail ? 'filled' : ''">
                    <input type="email" placeholder="Uw e-mailadres... *" v-model="contact.email" />
                </li>

                <li :class="contact.tel.length > 0 ? 'filled' : ''">
                    <input type="tel" placeholder="Uw telefoonnummer..." v-model="contact.tel" />
                </li>

                <li :class="contact.message.length > 0 ? 'filled' : ''">
                    <textarea placeholder="Uw bericht... *" v-model="contact.message" />
                </li>

                <li class="tools">
                    <a href="#" :class="buttonClasses" @click.prevent="submitForm" data-text="Versturen" aria-label="Versturen" />
                </li>
            </ul>
        </div>

    </form>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';

var vm;

export default {
    props: {
        page: Object,
    },


    components: {
        LoaderElement,
    },


    data()
    {
        return {
            contact: {
                name: '',
                email: '',
                tel: '',
                message: '',
            },
            processed: false,
            submitted: false,
            success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
        ]),


        buttonClasses() {
            let cl = [
                'button',
                'secondary',
                'stylized',
            ];

            if (!vm.valid) {
                cl.push('disabled');
            }

            return cl.join(' ');
        },


        processing() {
            return vm.processed !== vm.submitted;
        },


        valid() {
            if (vm.contact.name.length === 0) return false;
            if (vm.contact.email.length === 0) return false;
            if (vm.contact.message.length === 0) return false;

            if (!vm.validEmail) return false;

            return true;
        },


        validEmail() {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(vm.contact.email).toLowerCase());
        },
    },


    methods: {
        async submitForm() {
            if (vm.valid)
            {
                vm.submitted = true;
    
                let formData = new FormData();
    
                // for (let i = 0; i < vm.files.length; i++)
                // {
                // 	let f = vm.files[i];
                //
                // 	formData.append('files[' + i + ']', f);
                // }
    
                formData.append('action', 'contact');
                formData.append('data', JSON.stringify(vm.contact));
    
                if (process.env.NODE_ENV == 'development') {
                    setTimeout(() => {
                        vm.success = true;
                        vm.processed = true;
                    }, 2600);
                }
                else {
                    await axios.post(vm.base + 'api/forms/', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                        .then(response => {
                            vm.processed = true;
                            vm.success = response.data.success;
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            }
        },
    },


    created() {
        vm = this;
    },
}
</script>