<template>
    <main>
        <slot name="hero" />

        <section class="container columns">
            <div class="box thick-box" v-html="page.content" />
            
            <div class="box thick-box primary">
                <h2>
                    Contact formulier
                </h2>

                <ContactForm :page="page" />
            </div>
        </section>
    </main>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import ContactForm from '@/components/ContactForm.vue';

export default {
    props: {
        page: Object,
    },


    components: {
        ContactForm,
    },


    mounted() {
        let textAnim = {
            opacity: 0,
            duration: 1.3,
            ease: "expo",
            scrollTrigger: {
                start: 'top 80%',
            }
        },
        formAnim = JSON.parse(JSON.stringify(textAnim)),
        text = document.querySelector('.columns .box:nth-of-type(1)'),
        form = document.querySelector('.columns .box:nth-of-type(2)'),
        x = '5vw';

        textAnim.x = `-${x}`;
        textAnim.scrollTrigger.trigger = text;

        formAnim.x = x;
        formAnim.scrollTrigger.trigger = form;

        gsap.from(text, textAnim)
        gsap.from(form, formAnim)
    },
}
</script>