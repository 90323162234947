<template>
    <section class="row">
        <h2>
            {{ servicesPage.title }}
        </h2>

        <div v-for="(s, id) in services" class="service reveal" :key="id">
            <div class="box">
                <router-link :to="to(s)" tabindex="-1">
                    <figure :style="getImage(s)" />
                </router-link>
    
                <h3>
                    {{ s.pagetitle }}
                </h3>
    
                <p v-html="s.introtext" />
    
                <router-link :to="to(s)" class="button">
                    Lees verder
                </router-link>
            </div>
        </div>


        <div v-if="toOverview" class="service reveal">
            <div class="box primary">
                <router-link :to="to(servicesPage)" tabindex="-1">
                    <figure :style="getImage(servicesPage)" />
                </router-link>
    
                <h3>
                    {{ servicesPage.title }}
                </h3>
    
                <p v-html="servicesPage.introtext" />
    
                <router-link :to="to(servicesPage)" class="button secondary">
                    Naar het aanbod
                </router-link>
            </div>
        </div>
    </section>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        limit: {
            type: Number,
            default: 0,
        },
        toOverview: {
            type: Boolean,
            default: false,
        },
    },


    computed: {
        ...mapGetters([
            'duration',
            'screenWidth',
            'servicesPage',
        ]),


        services()
        {
            let limit = vm.limit > 0 ? vm.limit : vm.servicesPage.sections.length,
                services = [];

            for (let i = 0; i < limit; i++)
            {
                services.push(vm.servicesPage.sections[i]);
            }

            return services;
        },
    },
    
    
    setup()
    {
        const { getImage, to } = useFunctions();
        
        
        return {
            getImage,
            to,
        };
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        let perRow = 1;

        if (vm.screenWidth >= 1920) perRow = 4;
        else if (vm.screenWidth >= 768) perRow = 2;

        document.querySelectorAll('.service').forEach((el, id) => {
            let rowId = Math.floor(id / perRow),
                onRowId = ((id / perRow) - rowId) * perRow;
            
            gsap.from(el, {
                scale: 0.39,
                opacity: 0,
                duration: 1,
                delay: perRow === 1 ? 0 : (onRowId + rowId) * 0.13,
                ease: "expo",
                scrollTrigger: {
                    trigger: el,
                    start: 'top bottom',
                }
            })
        });
    },
}
</script>