<template>
    <footer :key="$route.path">
        <div class="maps">
            <div class="map-wrapper" v-for="(l, id) in locations" :key="id">
                <div class="map">
                    <iframe :src="`https://www.google.com/maps/embed/v1/place?key=${gApiKey}&amp;q=${siteName},${l.address},${l.postcode}+${l.city}`" allowfullscreen="allowfullscreen" width="600" height="450" frameborder="0"></iframe>
                    <span>
                        {{ l.address }}
                    </span>
                </div>
            </div>
        </div>

        <div class="footer-contact">
            <div class="contact-box">
                <a :href="mailLink" target="_blank">
                    <span class="icon">
                        <i class="fas fa-envelope"></i>
                    </span>
                    
                    <h5>
                        Mail ons
                    </h5>
                    
                    <span v-html="contact.variables.email" />
                </a>
            </div>


            <div class="contact-box">
                <a :href="phoneLink" target="_blank">
                    <span class="icon">
                        <i class="fas fa-phone"></i>
                    </span>
                
                    <h5>
                        Bel ons
                    </h5>
                
                    <span v-html="contact.variables.phone" />
                </a>
            </div>


            <div class="contact-box" v-for="(l, id) in locations" :key="id">
                <div>
                    <span class="icon">
                        <i class="fas fa-map-marker-alt"></i>
                    </span>
            
                    <h5>
                        Locatie
                    </h5>
            
                    <span v-if="l.hasOwnProperty('subtitle')">
                        {{ l.subtitle }}
                    </span>
                    <span>
                        {{ l.address }}
                    </span>
                    <span>
                        {{ l.postcode }}
                        {{ l.city }}
                    </span>
                </div>
            </div>


            <div class="contact-box">
                <div>
                    <span class="icon">
                        <i class="far fa-clock"></i>
                    </span>
                
                    <h5>
                        Openingstijden
                    </h5>
                
                    <ul>
                        <li v-for="(t, id) in times" :key="id">
                            <strong v-html="t.day" />
    
                            <span>
                                {{ t.title }}
                                <br v-if="t.hasOwnProperty('subtitle')" />
                                {{ t.subtitle }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ul class="footer-links">
            <li v-for="(item, id) in nav" :key="id">
                <router-link :to="{ path: '/' + item.path.slice(0, -1) }">
                    {{ item.pagetitle }}
                </router-link>
            </li>
        </ul>

        <div class="credits">
            <span>
                Copyright
                <i class="far fa-copyright"></i>
                {{ year }}
                {{ siteName }}
            </span>

            <span>
                Realisatie:

                <a href="https://dizero.nl" target="_blank" class="dizero">
                    Dizero
                </a>
            </span>
        </div>
    </footer>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { mapGetters } from 'vuex';

var vm;

export default {
    data()
    {
        return {
            gApiKey: 'AIzaSyCSORNEZEjkrEb8mJU7WlmwHWvxdElan9M',
            locations: [
                {
                    address: 'Rentmeesterlaan 33',
                    postcode: '4336 ED',
                    city: 'Middelburg',
                },
                // {
                //     address: 'Park Veldzigt 2',
                //     postcode: '4336 DX',
                //     city: 'Middelburg',
                // },
                {
                    subtitle: 'Gezondheidsplein Kruitmolen',
                    address: 'Kruitmolenlaan 185',
                    postcode: '4337 KP',
                    city: 'Middelburg',
                },
            ],
            year: new Date().getFullYear(),
        };
    },


    computed: {
        ...mapGetters([
            'contact',
            'pages',
            'siteName',
        ]),


        mailLink()
        {
            return `mailto:${vm.contact.variables.email}`;
        },


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.pages.length; i++)
            {
                let p = vm.pages[i];

                if (p.parent == 0 && p.hidemenu == 1 && p.alias != 'test')
                {
                    nav.push(p);
                }
            }

            return nav;
        },


        phoneLink()
        {
            let input = vm.contact.variables.phone.split(''),
                tel = [0, 0, 3, 1];

            for (let i = 0; i < input.length; i++)
            {
                let c = input[i];

                if (parseInt(c) == c)
                {
                    if (i > 0 || (i == 0 && parseInt(c) > 0))
                    {
                        tel.push(c)
                    }
                }
            }

            return `tel:${tel.join('')}`;
        },


        times()
        {
            let _times = vm.contact.variables.opened.split('\n'),
                times = [];

            for (let i = 0; i < _times.length; i++)
            {
                let t = _times[i].split('=='),
                    time = {
                        day: t[0],
                    };

                if (t.length > 1) time.title = t[1];
                if (t.length > 2) time.subtitle = t[2];

                times.push(time);
            }

            return times;
        },
    },


    methods: {
        setGSAP()
        {
            document.querySelectorAll('.footer-contact .contact-box').forEach((el) => {
                gsap.from(el, {
                    x: "50%",
                    opacity: 0,
                    duration: 1,
                    ease: "expo",
                    scrollTrigger: {
                        trigger: el,
                        start: 'center bottom',
                        end: 'bottom bottom',
                        scrub: 0.52,
                    }
                })
            });
        },
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.setGSAP();
    },


    updated()
    {
        setTimeout(vm.setGSAP, 520)
    }
}
</script>