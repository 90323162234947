<template>
    <header :class="open ? 'open' : ''">
        <div>
            <span>
                <router-link class="logo" :to="{ name: 'HomePage' }" exact>
                    <img src="img/logo.svg" :alt="siteName">
                </router-link>
            </span>
            
            <div class="contact">
                <a href="mailto:info@fysio-motion.nl">
                    <i class="far fa-envelope"></i>
                </a>

                <span>
                    Mail ons op
                    
                    <a :href="mailLink" tabindex="-1">
                        {{ contact.variables.email }}
                    </a>
                </span>
            </div>
            
            <div class="contact">
                <a :href="phoneLink">
                    <i class="fas fa-phone"></i>
                </a>
                
                <span>
                    Bel ons op
                    
                    <a :href="phoneLink" tabindex="-1">
                        {{ contact.variables.phone }}
                    </a>
                </span>
            </div>
            
            <a href="#" class="menu-toggle" @click.prevent="open = !open">
                <span></span>
                <span></span>
                <span></span>
            </a>
        </div>
    </header>

    <nav :class="open ? 'show' : ''">
        <div class="items">
            <span v-for="(item, id) in nav" :key="id" :class="[['group', item.parent].join('-'), ['item', item.id].join('-')].join(' ')">
                <router-link v-if="item.url === false" :key="id" :to="{ path: item.path }" exact>
                    {{ item.name }}
                </router-link>
    
                <a v-else :href="item.url" target="_blank">
                    {{ item.name }}
                </a>
            </span>
        </div>
    </nav>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    data()
    {
        return {
            open: false,
        };
    },


    computed: {
        ...mapGetters([
            'contact',
            'siteName',
            'structure',
        ]),


        mailLink()
        {
            return `mailto:${vm.contact.variables.email}`;
        },


        nav()
        {
            let fullNav = [],
                nav = vm.getNav(0);
            
            for (let i = 0; i < nav.length; i++)
            {
                let item = nav[i];

                fullNav.push(item);
                
                if (item.id == 60)
                {
                    fullNav = fullNav.concat(vm.getNav(item.id));
                }
            }

            return fullNav;
        },


        phoneLink()
        {
            let input = vm.contact.variables.phone.split(''),
                tel = [0, 0, 3, 1];

            for (let i = 0; i < input.length; i++)
            {
                let c = input[i];

                if (parseInt(c) == c)
                {
                    if (i > 0 || (i == 0 && parseInt(c) > 0))
                    {
                        tel.push(c)
                    }
                }
            }

            return `tel:${tel.join('')}`;
        },
    },


    methods: {
        getNav(parent)
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0 && item.parent == parent)
                {
                    if (item.path.length > 1 && item.path.substr(-1) == '/') item.path = item.path.substr(0, item.path.length - 1);

                    nav.push(item);
                }
            }

            return nav;
        },
    },



    created()
    {
        vm = this;
    },


    watch: {
        $route ()
        {
            vm.open = false;
        },
    }
}
</script>