<template>
    <main>
        <section class="container news">
            <aside class="row">
                <h2>
                    Nieuws
                </h2>
            </aside>

            <div v-for="(item, id) in items" class="box thick-box news-item" :key="id">
                <div v-if="item.variables.hasOwnProperty('image')" class="image">
                    <figure :style="getImage(item)" />
                </div>

                <div class="preview">
                    <h3 v-html="item.title" />
                    
                    <small v-html="getDate(item)" />
    
                    <div v-html="item.variables.previewText" />
    
                    <p>
                        <router-link :to="to(item)" class="button primary stylized" data-text="Lees meer" />
                    </p>
                </div>
            </div>
        </section>
    </main>
</template>


<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            limit: 8,
            steps: 4,
        };
    },


    computed: {
        items()
        {
            let items = [];

            for (let i = vm.page.sections.length - 1; i >= 0; i--)
            {
                items.push(vm.page.sections[i]);
            }

            items = items.slice(0, vm.limit);

            return items;
        },
    },


    methods: {
        getDate(item)
        {
            let date = new Date(item.publishedon.replace(' ', 'T'));

            return date.toLocaleString('nl-NL', { day: 'numeric', month: 'long', year: 'numeric' });
        },
    },


	setup()
	{
		const { getImage, to } = useFunctions();


		return {
            getImage,
            to,
		};
	},


    created()
    {
        vm = this;
    },


    mounted()
    {
        document.querySelectorAll('.news-item').forEach((el) => {
            gsap.from(el, {
                y: '4rem',
                opacity: 0,
                duration: 1.5,
                delay: 0.26,
                ease: "expo",
                scrollTrigger: {
                    trigger: el,
                    start: 'middle bottom',
                }
            })
        });
    },
}
</script>