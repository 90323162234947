<template>
    <main>
        <slot name="hero" />

        <team-members />
    </main>
</template>


<script>
import TeamMembers from '@/components/TeamMembers.vue';

export default {
    props: {
        page: Object,
    },


    components: {
        TeamMembers,
    },
}
</script>