import { createRouter, createWebHistory } from "vue-router";

import Contact from "@/views/ContactPage.vue";
import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/HomePage.vue";
import News from "@/views/NewsPage.vue";
import NewsItem from "@/views/NewsItemPage.vue";
import Services from "@/views/ServicesPage.vue";
import Team from "@/views/TeamPage.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: Home,
    },

    {
        path: "/aanbod",
        name: "ServicesPage",
        component: Services,
    },
    
    {
        path: "/contact",
        name: "ContactPage",
        component: Contact,
    },
    
    {
        path: "/nieuws",
        name: "NewsPage",
        component: News,
    },
    
    {
        path: "/nieuws/:alias",
        name: "NewsItemPage",
        component: NewsItem,
    },

    {
        path: "/team",
        name: "TeamPage",
        component: Team,
    },
	
    {
        path: "/:alias",
        name: "DefaultPage",
        component: DefaultPage,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        if (to)
        {
            return savedPosition ? savedPosition : { top: 0 };
        }
        else
        {
          return {};
        }
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
