<template>
    <main>
        <slot name="hero" />

        <services />
    </main>
</template>


<script>
import Services from '@/components/ServicesSection.vue';

export default {
    props: {
        page: Object,
    },


    components: {
        Services,
    },
}
</script>