<template>
    <main v-if="page.template == 3">
        <slot name="hero" />


        <section v-if="hasSubservices" class="container row">
            <h2>
                {{ page.title }}
            </h2>

            <div v-for="(s, id) in services" class="service subservice reveal" :key="id">
                <div class="box">
                    <router-link :to="to(s)">
                        <figure :style="getImage(s)" />
                    </router-link>
    
                    <h3>
                        {{ s.pagetitle }}
                    </h3>
    
                    <p v-html="s.introtext" />
    
                    <router-link :to="to(s)" class="button">
                        Lees verder
                    </router-link>
                </div>
            </div>
        </section>
        

        <section v-for="(s, id) in sections" :key="id" :class="getSectionClasses(s)">
            <div class="box thick-box" :class="s.variables.backgroundColor">
                <div class="content" :class="s.variables.imagePosition">
                    <h2 v-html="s.title" />
                    <div v-if="hasList(s)" class="list">
                        <a href="#" v-for="(item, id) in getList(s)" class="item" :key="id" :class="{ active: activeItem == item.id }" @click.prevent="setActiveItem(item.id)">
                            <h4>
                                <i class="fas fa-angle-right"></i>
                                
                                <span>
                                    {{ item.title }}
                                </span>
                            </h4>

                            <div :aria-hidden="activeItem == item.id" v-html="item.content" />
                        </a>
                    </div>
                    <div v-else v-html="s.content" />
                </div>

                <div v-if="hasImage(s)" class="image" :class="s.variables.imageSize">
                    <figure :style="getImage(s)" />
                </div>
            </div>
        </section>
    </main>


    <main v-else>
        <slot name="hero" />

        <section class="container box-container">
            <div class="box thick-box" v-for="(s, id) in page.sections" :key="id">
                <h2 v-html="s.title" />
                <div v-html="s.content" />
            </div>
        </section>
    </main>
</template>


<script>
/**
 * NOTE:    Template 3 is the service page, but their URLs have nothing to
 *          distinguish them from the defa
 */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            activeItem: 0,
        };
    },


    computed: {
        ...mapGetters([
            'pages',
        ]),


        hasSubservices()
        {
            return vm.services.length > 0;
        },


        sections()
        {
            let sections = [];

            for (let i = 0; i < vm.page.sections.length; i++)
            {
                let s = vm.page.sections[i];

                if (s.template == 9) sections.push(s);
            }

            return sections;
        },


        services()
        {
            let subs = [];

            for (let i = 0; i < vm.page.sections.length; i++)
            {
                let s = vm.page.sections[i];

                if (s.template == 3) subs.push(s)
            }

            return subs;
        },
    },


    methods: {
        getList(s)
        {
            let list = [];
            
            for (let i = 0; i < vm.pages.length; i++)
            {
                let p = vm.pages[i];

                if (p.parent == s.id) list.push(p);
            }

            return list;
        },


        getSectionClasses(s)
        {
            let cl = [
                'service-section',
                s.variables.imagePosition,
            ],
            containerClass = vm.hasImage(s) ? 'image-container' : 'container';

            cl.push(containerClass);

            return cl.join(' ');
        },


        hasImage(s)
        {
            return Object.prototype.hasOwnProperty.call(s, 'variables') && Object.prototype.hasOwnProperty.call(s.variables, 'image');
        },


        hasList(s)
        {
            for (let i = 0; i < vm.pages.length; i++)
            {
                if (vm.pages[i].parent == s.id) return true;
            }

            return false;
        },


        setActiveItem(id)
        {
            vm.activeItem = vm.activeItem == id ? 0 : id;
        },
    },


	setup()
	{
		const { getImage, to } = useFunctions();


		return {
            getImage,
            to,
		};
	},


    created()
    {
        vm = this;
    },


    mounted() {
        document.querySelectorAll('.service-section').forEach((el) => {
            gsap.from(el, {
                y: '4rem',
                opacity: 0,
                duration: 1,
                ease: "expo",
                scrollTrigger: {
                    trigger: el,
                    start: 'top 85%',
                }
            })
        });
    },
}
</script>