import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';
// import { resolve } from "core-js/es6/promise";

var base = 'https://www.fysio-motion.nl/';

export default createStore({
    state: {
		base: base,
		duration: 650,
		pages: {},
        screenWidth: 0,
		siteName: 'Fysio-Motion Middelburg',
		structure: {},
	},
	
	
	getters: {
		base: state => {
			return state.base;
		},


        contact: state => {
            for (let i = 0; i < state.pages.length; i++)
            {
                let p = state.pages[i];

                if (p.template == 4) return p;
            }

            return null;
        },
		
		
		duration: state => {
			return state.duration;
		},
		

        newsPage: state => {
            for (let i = 0; i < state.pages.length; i++)
            {
                let p = state.pages[i];

                if (p.template == 5) return p;
            }

            return null;
        },
		
		
		pages: state => {
			return state.pages;
		},
		
		
		screenWidth: state => {
			return state.screenWidth;
		},
		

        servicesPage: state => {
            for (let i = 0; i < state.pages.length; i++)
            {
                let p = state.pages[i];

                if (p.template == 12) return p;
            }

            return null;
        },

		
		siteName: state => {
			return state.siteName;
		},
		
		
		structure: state => {
			return state.structure;
		},
	},


	mutations: {
		setPages (state, value)
		{
			state.pages = value;
		},
		
		
		setScreenWidth (state, value)
		{
			state.screenWidth = value;
		},
		
		
		setStructure (state, value)
		{
			state.structure = value;
		},
	},


	actions: {
        _initialize: (context, payload) => {
			return axios.get(base + 'api/initialize.json', { params: payload })
			.then(response => {
				context.commit('setPages', response.data.pages);
                context.commit('setStructure', response.data.structure);
			})
			.catch(function (error)
			{
				console.log(error);
			});
		},


        updateScreenWidth: ({commit}, payload) => {
            commit('setScreenWidth', payload);
        },
	},
});
