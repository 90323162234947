import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function usePage() {
	const route = useRoute();
	const store = useStore();


	const allPages = computed(() => {
		return store.state.pages;
	});

	// const base = computed(() => {
	// 	return store.state.base;
	// });


	const page = computed(() => {
		// Get the pages for the current language
		let pages = allPages.value,
			page = {},
			routePath = route.path;

		if (typeof (pages) != 'undefined')
		{
			for (let i = 0; i < pages.length; i++)
			{
				let p = pages[i],
					pPath = '/' + p.path;
                
                if (pPath.length > 1) pPath = pPath.slice(0, -1);

				if (routePath == pPath) page = p;
			}
	
			if (page)
			{
				// Create some additional paramters
				page.hasImage = Object.keys(page).indexOf('variables') >= 0 && Object.keys(page.variables).indexOf('image') >= 0;
				// page.title = Object.keys(page).indexOf('longtitle') >= 0 ? page.longtitle : page.pagetitle;
			}
		}
		
		return page ? page : {};
	});


	return {
		page: page,
	};
}