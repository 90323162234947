<template>
    <main>
        <slot name="hero" />
        
        <section class="container box-container">
            <div class="box thick-box">
                <h4>
                    <router-link :to="{ name: 'NewsPage' }">
                        <i class="far fa-angle-double-left"></i>
                        
                        Terug naar het nieuws
                    </router-link>
                </h4>
                <h2 v-html="page.title" />
                <div v-html="page.content" />
            </div>
        </section>
    </main>
</template>


<script>
export default {
    props: {
        page: Object,
    },
}
</script>